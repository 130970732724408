import { FC } from "react";

import * as Sentry from "@sentry/browser";
import { QueryClient, QueryClientProvider, MutationCache, DefaultOptions } from "react-query";

const mutationCache = new MutationCache({});

const queryClient = new QueryClient({ mutationCache });

// TODO: Handle onboarding check better
const initialQueryInvalidators = [
  "update_workspace",
  "insert_destination",
  "insert_segment",
  "insert_connection",
  "insert_destination_instance",
  "insert_sync_request",
];

const options: DefaultOptions = {
  queries: {
    staleTime: 1000 * 60 * 5,
    retry: false,
    refetchOnWindowFocus: false,
    onError: () => {
      // handle all graphql errors with global toast and reporting
    },
  },
  mutations: {
    onError: () => {
      // handle all graphql errors with global toast and reporting
    },
    onSuccess: (data) => {
      if (queryClient) {
        const mutationKey = data && typeof data === "object" ? Object.keys(data)[0] : undefined;

        const mutationReturn = mutationKey && data && typeof data === "object" ? data[mutationKey] : undefined;
        if (mutationReturn === null) {
          Sentry.captureException("You do not have permission to make this change. Nothing we returned");
        }
        // every mutation except will invalidate all active queries
        // to override this behavior pass `onSuccess` to the mutation hook.
        if (mutationKey && initialQueryInvalidators.some((key) => mutationKey.includes(key))) {
          queryClient.invalidateQueries();
        } else {
          // small optimization to not invalidate initial query unless required
          queryClient.invalidateQueries({
            predicate: ({ queryKey }) => queryKey[0] !== "Initial",
          });
        }
      }
    },
  },
};

queryClient.setDefaultOptions(options);

export const QueryProvider: FC = ({ children }) => <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
